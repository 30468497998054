<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Заказы</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#new-order"
                >
                  Добавить
                </button>
              </div>
              <div class="col-md-4 offset-4">
                <div class="d-flex align-items-center justify-content-end">
                  <select class="form-select me-2 w-50" @change="filterOrderByStatus">
                    <option class="fst-italic" selected="" disabled="" value="">Выберите статус</option>
                    <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name.ru }}</option>
                  </select>
                </div>
              </div>
<!--              <div class="col-md-4 d-flex align-items-center">-->
<!--                <div class="form-group input-group mb-0">-->
<!--                  <input type="search" class="form-control" placeholder="Search...">-->
<!--                  <span class="input-group-text" id="basic-addon1">-->
<!--                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" stroke-width="1.5"-->
<!--                                stroke-linecap="round" stroke-linejoin="round"></circle>-->
<!--                        <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" stroke-width="1.5"-->
<!--                              stroke-linecap="round" stroke-linejoin="round"></path>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>

          <div class="card-body px-0">
            <div class="table-responsive" style="min-height: 250px;">
              <table id="user-list-table" class="table overflow-auto" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tbody v-if="orders?.length">
                <tr v-if="$route.query.id">
                  <td align="center" colspan="10">
                    <p>Заказы пользователя, относящиеся к ID {{ $route.query.id }}</p>
                    <a href="/admin/orders">Показать все заказы</a>
                  </td>
                </tr>
                <tr v-for="({ order, user }, index) in orders" :key="index">
                  <td>
                    <button class="btn btn-link text-decoration-underline"
                            @click="showOrderDetailModal(order.order_id)">
                      {{ order.order_id }}
                    </button>
                  </td>
                  <td>{{ order.tracking_number }}</td>
                  <td>{{ order.country.name.ru|| 'Default country' }}</td>
                  <td>{{ user.first_name }} {{ user.last_name }}</td>
                  <td>{{ order.total_price }}</td>
                  <td>{{ order.total_weight }}</td>
                  <td>{{ order.flight_number }}</td>
                  <td>{{ order.arrival_date }}</td>
                  <td :class="{ 'text-success': order.status?.status === 1 }">{{ order.status?.name.ru }}</td>
                  <td>
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-expanded="false">
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item" href="#" @click="showOrderDetailModal(order.order_id)">Открыть</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#" @click="showOrderDetailModal(order.order_id)">Редактировать</a>
                        </li>
                        <li>
                          <button class="dropdown-item btn-link text-danger" @click="deleteOrder(order.order_id)">
                            Удалить
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="10" align="center">
                      {{ $route.query.id ? 'Пользователь пока не заказал ничего' : 'Пусто' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="pagination?.total > 10" class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="pagination?.total_pages || 1"
                :total="pagination?.total || 1"
                :per-page="10"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create order Modal -->
    <modal
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="new-order"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Новый заказ</h1>

        <Form v-slot="{ meta }" class="row mt-4" @submit="addOrder">
          <div class="col-md-6">
            <label for="user" class="form-label">Телефон пользователя</label>
            <div class="input-group">
              <input
                id="user"
                type="text"
                class="form-control"
                :value="userPhone"
                @input="(e) => userPhone = e.target.value"
                @focus="onFocusUserSearch"
              />
              <button
                class="input-group-text cursor-pointer"
                type="button"
                :disabled="userPhone === null || userPhone === ''"
                @click="getUserByPhone"
              >
                Поиск
              </button>
            </div>
            <p v-if="!users?.length" class="text-danger">Напишите номер телефона</p>

            <Field
              v-show="users?.length && hasShowUsersList"
              id="users"
              size="3"
              as="select"
              class="form-control w-100 mt-2"
              name="user_id"
              rules="required"
              style="overflow-y: scroll;"
            >
                <option v-for="user in users" :key="user.id" :value="user.id" @click="onSelectUser(user.user_phone)">{{ user.user_phone }}</option>
            </Field>
            <ErrorMessage name="user_id" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="country" class="form-label">Страна заказа</label>
            <Field id="country" as="select" class="form-select" rules="required" name="country_id">
              <option class="fst-italic" selected="" disabled="" value="">Выберите страну</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name.ru }}</option>
            </Field>
            <ErrorMessage name="country_id" class="text-danger" />
          </div>

          <div class="mt-4"></div>

          <div class="col-md-6">
            <label for="status" class="form-label">Статус</label>
            <Field id="status" as="select" class="form-select" rules="required" name="status">
              <option class="fst-italic" selected="" disabled="" value="">Выберите статус</option>
              <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name.ru }}</option>
            </Field>
            <ErrorMessage name="status" class="text-danger" />
          </div>

          <div class="col-md-6 d-flex align-items-end justify-content-end">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty) || !products.length">Сохранить</button>
          </div>
        </Form>

        <div class="mt-4">
          <hr>
        </div>

        <Form class="row" v-slot="{ meta }" @submit="onAddProduct" ref="productForm">
          <h3 class="text-center">Добавиить продукты</h3>

          <div class="d-flex justify-content-end mb-4">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Добавить</button>
          </div>

          <div class="col-md-6">
            <label for="product_name" class="form-label">Наименования</label>
            <Field id="product_name" class="form-control" rules="required" name="name" />
            <ErrorMessage name="name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="product_quantity" class="form-label">Количество</label>
            <Field id="product_quantity" type="number" class="form-control" rules="required" name="quantity" />
            <ErrorMessage name="quantity" class="text-danger" />
          </div>

          <div class="my-2"></div>

          <div class="col-md-6">
            <label for="product_price" class="form-label">Цена (в долларах $)</label>
            <Field id="product_price" type="number" class="form-control" rules="required" name="price" />
            <ErrorMessage name="price" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="product_link" class="form-label">Ссылка</label>
            <Field id="product_link" class="form-control" rules="required|url" name="link" />
            <ErrorMessage name="link" class="text-danger" />
          </div>

          <div class="my-2"></div>

          <div class="col-md-6">
            <label for="product_weight" class="form-label">Вес</label>
            <Field id="product_weight" class="form-control" rules="required" name="weight" />
            <ErrorMessage name="weight" class="text-danger" />
          </div>

          <div class="mt-4">
            <ol v-if="products.length">
              <li v-for="(product, index) in products" :key="index" class="mb-2">
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-between">
                    <p class="fw-bold ml-3">
                      {{ product.name }}
                    </p>

                    <p>
                      {{ product.price.toLocaleString() }} x {{ product.quantity }}
                    </p>
                  </div>

                  <button class="btn btn-danger btn-sm" type="button" @click="onRemoveProduct(product.id)">&times;</button>
                </div>
              </li>
            </ol>
          </div>
        </Form>

        <br><br>
      </model-body>
    </modal>

    <!-- order detail Modal -->
    <modal
      ref="orderDetailModal"
      mainClass="fade"
      :tabindex="-1"
      id="show-order"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-xl modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">

        <div v-if="!computedOrder">Загрузка....</div>

        <div v-else>
          <h1 class="text-center">Заказ #{{ computedOrder.order.order_id }}</h1>

          <div class="row mt-5">
            <div class="col-md-6">
              <p class="fw-bold text-black">Пользователь</p>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <td>ФИО</td>
                    <td>{{ computedOrder.user.first_name || 'Bob' }} {{ computedOrder.user.last_name || 'Marley' }}</td>
                  </tr>
                  <tr>
                    <td>Паспорт</td>
                    <td>{{ computedOrder.user.passport || 'AA 4631214' }}</td>
                  </tr>
                  <tr>
                    <td>Номер телефона</td>
                    <td>{{ computedOrder.user.phone || '+998 99 111-22-33' }}</td>
                  </tr>
                  <tr>
                    <td>Фото паспорта</td>
                    <td>
                      <a v-if="computedOrder.user.image"
                         :href="computedOrder.user.image"
                         target="_blank"
                         class="text-decoration-underline"
                      >
                        Открыть
                      </a>
                      <span v-else>&times;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Дата регистрации</td>
                    <td>{{ computedOrder.user.created_at }}</td>
                  </tr>
                  <tr>
                    <td>Адрес доставки</td>
                    <td class="text-wrap">{{ computedOrder.user.address || '1901 Thornridge Cir. Shiloh, Hawaii 81063' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <p class="fw-bold text-black">Заказ</p>
              <table class="table table-sm table-borderless">
                <tbody>
                <tr>
                  <td>Дата заказа</td>
                  <td>{{ computedOrder.order.order_date }}</td>
                </tr>
                <tr>
                  <td>Страна заказа</td>
                  <td>{{ computedOrder.order.country.name.ru }}</td>
                </tr>
                <tr>
                  <td>Трекинг номер</td>
                  <td>{{ computedOrder.order.tracking_number }}</td>
                </tr>
                <tr>
                  <td>Стоимость товара</td>
                  <td>{{ computedOrder.order.product_price }}</td>
                </tr>
                <tr>
                  <td>Добавочная стоимость</td>
                  <td>{{ computedOrder.order.delivery_price }}</td>
                </tr>
                <tr>
                  <td>Общая сумма</td>
                  <td>{{ computedOrder.order.total_price }}</td>
                </tr>
                <tr>
                  <td>Вес (кг)</td>
                  <td>{{ computedOrder.order.weight }}</td>
                </tr>
                <tr>
                  <td>Доставка</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Рейс</td>
                  <td>{{ computedOrder.order.flight_number }}</td>
                </tr>
                <tr>
                  <td>Дата прилета</td>
                  <td>{{ computedOrder.order.arrival_date }}</td>
                </tr>
                <tr>
                  <td>Статус</td>
                  <td>{{ computedOrder.order.status?.name.ru }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="computedOrder.order.products.length" class="row mt-5">
            <p class="fw-bold text-black">Продукты</p>

            <div class="row">
              <div v-for="product in computedOrder.order.products" :key="product.id" class="col-xl-3 col-md-4">
                <div class="card shadow p-3" style="border-radius: 20px">
                  <div>
                    <b>Названия:</b> &nbsp;
                    <a :href="product.link" target="_blank">
                      {{ product.name }}
                    </a>
                  </div>
                  <div class="d-flex justify-content-between flex-column">
                    <div>
                      <b>Цена:</b> <span>{{ product.price }}</span>
                    </div>
                    <div>
                      <b>Вес:</b> <span>{{ product.weight }}</span>
                    </div>
                    <div>
                      <b>Кол-во:</b> <span>{{ product.quantity }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center my-5">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#edit-order">
              Редактировать
            </button>
          </div>
        </div>
      </model-body>
    </modal>

    <!-- order edit Modal -->
    <modal
      ref="ordersEditModal"
      mainClass="fade"
      :tabindex="-1"
      id="edit-order"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-xl modal-dialog-scrollable text-black"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">

        <div v-if="!computedOrder">Загрузка....</div>

        <div v-else>
          <h1 class="text-center">Заказ #{{ computedOrder.order.order_id }}</h1>

          <div class="row mt-5">
            <div class="col-md-6">
              <p class="fw-bold text-black">Пользователь</p>
              <table class="table table-sm table-borderless">
                <tr>
                  <td>ФИО</td>
                  <td>{{ computedOrder.user.first_name || 'Bob' }} {{ computedOrder.user.last_name || 'Marley' }}</td>
                </tr>
                <tr>
                  <td>Номер телефона</td>
                  <td>{{ computedOrder.user.phone || '+998 99 111-22-33' }}</td>
                </tr>
                <tr>
                  <td>Адрес доставки</td>
                  <td class="text-wrap">{{ computedOrder.user.address || '1901 Thornridge Cir. Shiloh, Hawaii 81063' }}</td>
                </tr>
              </table>
            </div>
            <div class="col-md-6">
              <p class="fw-bold text-black">Заказ</p>
              <table class="table table-sm table-borderless">
                <tr>
                  <td>Дата заказа</td>
                  <td>{{ computedOrder.order.order_date }}</td>
                </tr>
                <tr>
                  <td>Страна заказа</td>
                  <td>{{ computedOrder.order.country.name.ru }}</td>
                </tr>
                <tr>
                  <td>Стоимость товара</td>
                  <td>{{ computedOrder.order.product_price }}</td>
                </tr>
                <tr>
                  <td>Добавочная стоимость</td>
                  <td>{{ computedOrder.order.delivery_price }}</td>
                </tr>
                <tr>
                  <td>Общая сумма</td>
                  <td>{{ computedOrder.order.total_price }}</td>
                </tr>
              </table>
            </div>
          </div>

          <Form v-slot="{ meta }" class="row mt-4" @submit="editOrder">
            <div class="col-md-6">
              <label for="tracking-number" class="form-label">Трекинг номер</label>
              <Field id="tracking-number" type="number" class="form-control" rules="required" name="tracking_number" />
              <ErrorMessage name="tracking_number" class="text-danger" />
            </div>

            <div class="col-md-6">
              <label for="flight" class="form-label">Рейс</label>
              <Field id="flight" as="select" class="form-select" rules="required" name="flight_number_id">
                <option class="fst-italic" selected="" disabled="" value="">Выберите рейс</option>
                <option
                  v-for="flight in flights"
                  :key="flight.id"
                  :value="flight.id"
                >
                  {{ flight.flight_number }}
                </option>
              </Field>
              <ErrorMessage name="flight_number_id" class="text-danger" />
            </div>

            <div class="my-2"></div>

            <div class="col-md-6">
              <label for="status" class="form-label">Статус</label>
              <Field id="status" class="form-select" as="select" rules="required" name="status">
                <option class="fst-italic" selected="" disabled="" value="">Выберите статус</option>
                <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name.ru }}</option>
              </Field>
              <ErrorMessage name="status" class="text-danger" />
            </div>

            <div class="row mt-4">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <p class="fw-bold text-black mb-0">Продукты</p>

                <button class="btn btn-link" type="button" @click="onIncreaseProduct">
                  &plus;&nbsp;Добавить
                </button>
              </div>

              <div v-for="(product, index) in computedOrder.order.products" :key="product.id" class="col-xl-6 col-md-6">
                <div class="card p-3">
                  <Field type="hidden" :value="product.id" :name="`products[${index}].id`" />
                  <div>
                    <label :for="`products[${index}].name`" class="form-label">Названия</label>
                    <Field :id="`products[${index}].name`" type="text" :value="product.name" class="form-control" rules="required" :name="`products[${index}].name`" />
                    <ErrorMessage :name="`products[${index}].name`" class="text-danger" />
                  </div>
                  <div class="mt-2">
                    <label :for="`products[${index}].price`" class="form-label">Цена ($)</label>
                    <Field :id="`products[${index}].price`" type="text" :value="product.price" class="form-control" rules="required" :name="`products[${index}].price`" />
                    <ErrorMessage :name="`products[${index}].price`" class="text-danger" />
                  </div>
                  <div class="mt-2">
                    <label :for="`products[${index}].weight`" class="form-label">Вес</label>
                    <Field :id="`products[${index}].weight`" type="text" :value="product.weight" class="form-control" rules="required" :name="`products[${index}].weight`" />
                    <ErrorMessage :name="`products[${index}].weight`" class="text-danger" />
                  </div>
                  <div class="mt-2">
                    <label :for="`products[${index}].quantity`" class="form-label">Кол-во</label>
                    <Field :id="`products[${index}].quantity`" type="text" :value="product.quantity" class="form-control" rules="required" :name="`products[${index}].quantity`" />
                    <ErrorMessage :name="`products[${index}].quantity`" class="text-danger" />
                  </div>
                  <div class="mt-2">
                    <label :for="`products[${index}].link`" class="form-label">Ссылка</label>
                    <Field :id="`products[${index}].link`" type="text" :value="product.link" class="form-control" rules="required|url" :name="`products[${index}].link`" />
                    <ErrorMessage :name="`products[${index}].link`" class="text-danger" />
                  </div>

                  <div class="card-footer d-flex justify-content-center">
                    <button class="btn btn-link" @click="onDecreaseProduct(index)">
                      &minus;&nbsp; Удалить
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center my-5">
              <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
            </div>
          </Form>
        </div>
      </model-body>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import moment from 'moment'
import { ErrorMessage, Field, Form } from 'vee-validate'
import modal from '@/components/bootstrap/modal/modal'
import modelHeader from '@/components/bootstrap/modal/model-header'
import modelBody from '@/components/bootstrap/modal/model-body'
import CustomPagination from '@/components/custom/pagination/CustomPagination'

import { getStatuses } from '@/services/statuses.service'
import { createOrder, getOrder, getOrders, updateOrder, removeOrder } from '@/services/order.service'
import { getCountries } from '@/services/countries.service'
import { getUserByPhone } from '@/services/user.service'
import { getFlights } from '@/services/flights.service'

export default {
  name: 'OrdersIndex',
  data: () => ({
    currentPage: 1,
    price: 0,
    extraPrice: 500,
    deliveryPrice: 8,
    weight: 0,
    pagination: null,
    statuses: null,
    orders: null,
    order: null,
    orderId: null,
    modal: null,
    countries: null,
    users: null,
    userId: null,
    userPhone: null,
    showModal: false,
    products: [],
    hasShowUsersList: false,
    trackingNumbers: [],
    flights: [],
    isLoading: {
      order: false
    },
    tableHeaders: [
      'Номер заказа',
      'Трек номер',
      'Страна заказа',
      'Пользователь',
      'Сумма товара +5%',
      'Вес (кг)',
      'Рейс',
      'Дата прилета',
      'Статус',
      'Действия'
    ]
  }),
  components: {
    CustomPagination,
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  },
  computed: {
    computedOrder () {
      return this.order
    }
  },
  methods: {
    onFocusUserSearch () {
      this.hasShowUsersList = true
    },
    onSelectUser (phone) {
      this.userPhone = phone
      this.hasShowUsersList = false
    },
    onPageChange (page) {
      this.currentPage = page
      this.fetchOrders()
    },
    onIncreaseProduct () {
      this.order.order.products.push({
        name: '',
        price: 0,
        quantity: 0,
        weight: 0,
        link: ''
      })
    },
    onAddProduct (product, { resetForm }) {
      this.products.push(product)
      resetForm()
    },
    onDecreaseProduct (index) {
      this.order.order.products.splice(index, 1)
    },
    onRemoveProduct (productId) {
      this.products = this.products.filter(product => product.id === Number(productId))
    },
    async addOrder (values) {
      const formData = new FormData()

      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, String(value))
      })

      this.products.forEach((product, index) => {
        Object.entries(product).forEach(([name, value]) => {
          formData.append(`products[${index}][${name}]`, String(value))
        })
      })

      await createOrder(formData)

      window.location.reload()
    },
    async fetchOrder (orderId) {
      this.isLoading.order = true

      const { data: orderResponse } = await getOrder(orderId)
      this.order = orderResponse

      this.isLoading.order = false
    },
    async fetchOrders (params) {
      const { data: orderResponse } = await getOrders({
        paginate: 10,
        page: this.currentPage,
        status: params?.status || undefined
      })
      const userId = this.$route.query.id

      if (userId) {
        this.orders = orderResponse.data.filter(order => order.user.id === Number(userId))
        return
      }

      this.orders = orderResponse.data
      this.pagination = orderResponse.pagination
    },
    async editOrder (values) {
      const formData = new FormData()
      const products = values.products
      delete values.products

      formData.append('order_id', this.computedOrder.order.order_id)

      Object.entries(values).forEach(([name, value]) => {
        if (name === 'arrival_date') {
          value = moment(value).format('Y-m-d H:m')
        }
        formData.append(name, String(value))
      })

      products.forEach((product, index) => {
        Object.entries(product).forEach(([name, value]) => {
          if (value !== undefined) {
            formData.append(`products[${index}][${name}]`, String(value))
          }
        })
      })

      await updateOrder(formData)

      window.location.reload()
    },
    async deleteOrder (id) {
      if (confirm('Are you sure delete this order ?')) {
        await removeOrder(id)
        await this.fetchOrders()
      }
    },
    async filterOrderByStatus (e) {
      const statusId = e.target.options[e.target.selectedIndex].value || undefined
      await this.fetchOrders({ status: statusId })
    },
    async fetchFlights () {
      const { data: flightsResponse } = await getFlights()
      this.flights = flightsResponse.data
    },
    async fetchStatuses () {
      const { data: statusesResponse } = await getStatuses()
      this.statuses = statusesResponse.data
    },
    async fetchCountries () {
      const { data: countriesResponse } = await getCountries()
      this.countries = countriesResponse.data
    },
    async getUserByPhone () {
      this.hasShowUsersList = true
      this.userId = null
      const { data: userResponse } = await getUserByPhone(this.userPhone)
      this.users = userResponse.data
    },
    async showOrderDetailModal (orderId) {
      const orderDetailModal = new Modal(document.getElementById('show-order'), {
        keyboard: false
      })

      await this.fetchOrder(orderId)
      await orderDetailModal.show()
    }
  },
  async created () {
    await this.fetchOrders()
    await this.fetchStatuses()
    await this.fetchCountries()
    await this.fetchFlights()
  }
}
</script>
