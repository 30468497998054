import ApiClient from '../api'
import { Order } from '../models'

const api = new ApiClient(Order.modelName)
const landingApi = new ApiClient(Order.modelName, true)

export const getOrders = async (params) => {
  const { data: orders } = await api.getAll(params)

  return orders
}

export const getOrder = async (id) => {
  const { data: order } = await api.getOne(id)

  return order
}

export const createOrder = async (formData) => {
  const { data: order } = await api.create(formData)

  return order
}

export const updateOrder = async (formData) => {
  const { data: order } = await api.update(formData)

  return order
}

export const removeOrder = async (id) => {
  return await api.delete(id)
}

export const getLandingOrders = async (params) => {
  const { data: orders } = await landingApi.getAll(params)

  return orders
}
